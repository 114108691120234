<template>
  <v-dialog v-model="show" width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">Modifier un événement</span>
      </v-card-title>

      <!-- le type -->
      <v-card-text>
        <v-row no-gutters align="center">
          <!-- le type -->
          <v-col :cols="8" justify="center" align-self="center" align="start">
            <v-autocomplete
              return-object
              no-data-text="aucun type d'évènement"
              :items="types"
              v-model="type"
              item-text="label"
              placeholder="Type"
              hint="Choisir un type d'événement"
              persistent-hint
            ></v-autocomplete>
          </v-col>

          <!-- la date -->
          <v-col :cols="4" align-self="center" align="start">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center justify-center">
                  <v-btn
                    color="primary"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    class="ml-5"
                    ><v-icon>mdi-calendar</v-icon></v-btn
                  >
                  <v-text-field
                    v-model="date"
                    max-width="190px"
                    class="ml-2"
                    hint="Date"
                    persistent-hint
                    placeholder="date de l'événement"
                  ></v-text-field>
                </div>
              </template>

              <v-date-picker
                v-model="datePicker"
                no-title
                locale="fr"
                first-day-of-week="1"
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-text>
        <v-row no-gutters align="center">
          <v-textarea
            hint="Description de l'événement"
            persistent-hint
            v-model="description"
            clearable
            filled
          ></v-textarea>
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-text>
        <v-row no-gutters align="center">
          <!-- le type -->
          <v-col :cols="11" justify="center" align-self="center" align="start">
            <!--  -->
            <v-text-field
              hint="Lien vers le document"
              persistent-hint
              v-model="link"
              clearable
              :error="!isUrlValid"
            ></v-text-field>
          </v-col>

          <v-col :cols="1" align-self="center" align="start">
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                icon
                :disabled="disableButtonOpen"
                @click="openLinkTab"
                ><v-icon>mdi-open-in-new</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="abort()" class="btn">Annuler</v-btn>
        <v-btn
          outlined
          color="primary"
          class="mx-2 my-2 px-4 btn"
          @click="confirm"
          :disabled="!canSave"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  isValidFR,
  format_MM_DD_YYYY_to_FR,
  convert_FR_to_date,
  format_for_datepicker,
} from "@/tools/date_tool.js";

import { checkUrl } from "@/tools/url_tool.js";

export default {
  name: "EditEventDialog",
  components: {},

  mixins: [],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    types: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**les valeurs courantes */
    current: {
      type: Object,
      default: function () {
        return {
          eventId: null,
          typeId: null,
          date: null,
          description: null,
          link: null,
        };
      },
    },
  },
  data() {
    return {
      show: this.visible,

      /**L'identifiant de l'événement à éditer */
      eventId: null,

      /**le type sélectionné */
      type: null,

      /**la date */
      date: null,

      /**la description */
      description: null,

      /**le lien du document */
      link: null,

      datePicker: null,

      /**afficher le menu de sélection de la date */
      menu: false,
    };
  },
  methods: {
    /**l'action n'est pas confirmée. on ferme le dialog.*/
    abort() {
      this.close();
    },

    /**l'action est confirmée.*/
    confirm() {
      let item = {
        eventId: this.eventId,
        type: this.type,
        date: this.date,
        description: this.description,
        link: this.link,
      };
      // console.log(JSON.stringify(item, null, 4));
      this.$emit("confirmed", item);

      this.close();
    },

    /**Fermer le dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },
    openLinkTab() {
      let url = this.link;
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }
      window.open(url, "_blank");
    },
  },
  computed: {
    canSave() {
      //il faut un type de contrat
      if (!this.type) {
        return false;
      }

      // ajouter les vérifis
      if (!isValidFR(this.date)) {
        return false;
      }

      return true;
    },
    isUrlValid() {
      if (!this.link) {
        return true;
      }
      return checkUrl(this.link);
    },
    disableButtonOpen() {
      if (!this.link) {
        //pas de lien, on désactive le bouton
        return true;
      }

      if (!checkUrl(this.link)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.type = null;
        this.date = null;
        this.datePicker = null;
        this.description = null;
        this.link = null;

        this.$emit("update:visible", false);
      }
    },
    current(value) {
      if (value) {
        this.eventId = value.eventId;
        this.type = this.types.find((type) => type.id == value.typeId);
        this.date = value.date;
        if (this.date) {
          this.datePicker = format_for_datepicker(
            convert_FR_to_date(this.date)
          );
        }
        this.description = value.description;
        this.link = value.link;
      } else {
        this.type = null;
        this.date = null;
        this.description = null;
        this.link = null;
      }
    },
    datePicker() {
      if (this.datePicker) {
        this.date = format_MM_DD_YYYY_to_FR(this.datePicker);
      } else {
        this.date = null;
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>

