var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _vm.canEdit
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn",
                  attrs: { outlined: "", color: "primary" },
                  on: { click: _vm.onShowAddDialog },
                },
                [_vm._v(" Ajouter un contrat ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("v-data-table", {
            attrs: {
              locale: "fr-FR",
              headers: _vm.headers,
              items: _vm.contracts,
              "no-data-text": "aucun contrat",
              "disable-pagination": "",
              "hide-default-footer": "",
              loading: _vm.loading,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: _vm.getItemActions(),
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-grow-0 justify-center" },
                        [
                          _vm.canEdit
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowEditDialog(item)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-pencil ")]
                              )
                            : _vm._e(),
                          _vm.canEdit
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowDeleteDialog(item)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-close-thick ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("AddExternalContractDialog", {
            attrs: {
              visible: _vm.showAddDialog,
              types: _vm.types,
              contacts: _vm.contacts,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddDialog = $event
              },
              confirmed: _vm.onAddExternalContract,
            },
          }),
          _c("EditExternalContractDialog", {
            attrs: {
              visible: _vm.showEditDialog,
              types: _vm.types,
              contacts: _vm.contacts,
              current: _vm.current,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showEditDialog = $event
              },
              confirmed: _vm.onEditExternalContract,
            },
          }),
          _c(
            "v-dialog",
            {
              attrs: { width: "400px" },
              model: {
                value: _vm.showDeleteDialog,
                callback: function ($$v) {
                  _vm.showDeleteDialog = $$v
                },
                expression: "showDeleteDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Confirmer la suppression"),
                    ]),
                  ]),
                  _c("v-card-text", [
                    _vm._v("Voulez-vous supprimer ce contrat externe?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDeleteDialog = false
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2 my-2 px-4 btn",
                          attrs: { outlined: "", color: "primary" },
                          on: { click: _vm.confirmDeleteExternalContract },
                        },
                        [_vm._v("Supprimer")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                color: _vm.snackbarColor,
                timeout: _vm.snackbarTimeout,
                left: _vm.snackbarLeft,
                right: _vm.snackbarRight,
                top: _vm.snackbarTop,
                bottom: _vm.snackbarBottom,
              },
              model: {
                value: _vm.snackbarVisible,
                callback: function ($$v) {
                  _vm.snackbarVisible = $$v
                },
                expression: "snackbarVisible",
              },
            },
            [_vm._v(_vm._s(_vm.snackbarMessage))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }