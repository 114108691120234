<template>
  <v-dialog v-model="show" width="700px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >Ajouter un actionnaire, membre ou adhérent</span
        >
      </v-card-title>

      <v-card-text>
        <v-row no-gutters align="center">
          <v-col :cols="5" justify="center" align-self="center" align="start">
            <div class="label-field capitalize">
              actionnaire, membre ou adhérent
            </div>
          </v-col>

          <!-- la donnée associée -->
          <v-col :cols="7" align-self="center" align="start">
            <v-autocomplete
              :items="availables"
              v-model="selected"
              item-text="name"
              return-object
              no-data-text
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-text>
        <v-row no-gutters align="center">
          <v-col :cols="5" justify="center" align-self="center" align="start">
            <div class="label-field capitalize">pourcentage</div>
          </v-col>

          <!-- la donnée associée  -->
          <v-col :cols="7" align-self="center" align="start">
            <v-text-field
              placeholder="pourcentage"
              dense
              v-model="percent"
              type="number"
              :rules="[rules.range0to100]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="abort()" class="ma-2 px-4 btn">Annuler</v-btn>
        <v-btn
          outlined
          color="primary"
          class="ma-2 px-4 btn"
          @click="confirm"
          :disabled="!canSave"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { StockholdersService } from "@/service/etablishment/stockholders_service.js";

export default {
  name: "AddStockholdersDialog",
  components: {},

  mixins: [],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    /**les identifiants des actionnaires existants sur la structure juridique */
    used: {
      type: Array,
    },
  },
  data() {
    return {
      show: this.visible,

      /**les actionnaires disponibles */
      availables: [],

      /**l'actionnaire sélectionné */
      selected: null,

      /**le pourcentage de l'actionnaire */
      percent: null,

      /**le service actionnaires */
      service: null,

      rules: {
        range0to100: (value) => {
          // console.log(value + " -> " + typeof value);
          // let number = new Number(value);
          // if (isNaN(number)) {
          //   return "Veuillez saisir un nombre 2";
          // }

          if (value < 0 || value > 100) {
            return "Veuillez saisir un nombre entre 0 et 100";
          } else {
            return true;
          }
        },
      },
    };
  },
  methods: {
    /**l'action n'est pas confirmée. on ferme le dialog.*/
    abort() {
      this.close();
    },

    /**l'action est confirmée.*/
    confirm() {
      this.$emit("confirmed", {
        stockholderId: this.selected.id,
        percent: Number.parseFloat(this.percent),
      });
      this.close();
    },

    /**Fermer le dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },

    async load() {
      let stockholders = await this.service.getAll();

      // filtrer les utilisés
      stockholders = stockholders.filter((s) => !this.used.includes(s.id));

      this.availables = stockholders;

      //   console.error("actionnaires:" + JSON.stringify(stockholders, null, 4));
      //   console.error("used:" + JSON.stringify(this.used, null, 4));
    },
  },
  computed: {
    canSave() {
      if (!this.selected) return false;

      if (this.percent == null) return false;
      if (this.percent == "") return false;

      let value = Number.parseFloat(this.percent);

      if (isNaN(value)) {
        return false;
      }

      if (value > 100.0) {
        return false;
      }

      if (value < 0.0) {
        return false;
      }

      return true;
    },
  },
  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.selected = null;
        this.percent = null;
        this.$emit("update:visible", false);
      }

      //on affiche le dialog
      if (value) {
        this.load();
      }
    },
  },
  mounted() {
    this.service = new StockholdersService(this.$api.getStockholdersApi());
  },
};
</script>

<style></style>
