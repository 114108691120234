/**retourne true si date est valide en Français (fr-FR) c.a.d au format dd/MM/yyyy. */
export function isValidFR(date, minYear = 1900) {
  try {
    let parts = date.split("/");

    if (parts.length != 3) {
      return false;
    }

    let days = +parts[0];
    let months = +parts[1];
    let years = +parts[2];

    const d = new Date(years, +months - 1, days);

    if (years < minYear) {
      return false;
    }

    if (months > 12) {
      return false;
    }

    if (days > 31) {
      return false;
    }

    const valid = Boolean(+d) && d.getDate() == days;

    return valid;
  } catch (error) {
    // console.error("la date n'est pas valide:" + date + "->" + error);
    return false;
  }
}

/**formater une date au format EN en format FR  */
export function format_MM_DD_YYYY_to_FR(date) {
  let dt = new Date(date);

  return new Intl.DateTimeFormat("fr-FR").format(dt);
}

/** Convertir une date au format datepicker YYYY-MM-DD.*/
export function format_for_datepicker(date) {
  let year = +date.getFullYear();
  let months = +date.getMonth() + 1;
  let days = +date.getDate();

  return (
    year +
    "-" +
    (months < 10 ? "0" : "") +
    months +
    "-" +
    (days < 10 ? "0" : "") +
    days
  );
}

/**Convertir la date au format FR (dd/MM/yyyy) en objet date */
export function convert_FR_to_date(date, separator = "/") {
  let parts = date.split(separator);

  if (parts.length != 3) {
    return false;
  }

  let days = +parts[0];
  let months = +parts[1];
  let years = +parts[2];

  return new Date(years, +months - 1, days);
}
