<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <v-btn class="ml-4 btn"
                outlined
                color="primary" v-if="canEdit" @click="onShowAddDialog">
        Ajouter un contrat
      </v-btn>
    </div>

    <v-container fluid>
      <v-data-table
        locale="fr-FR"
        :headers="headers"
        :items="contracts"
        no-data-text="aucun contrat"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:[getItemActions()]="{ item }">
          <div class="d-flex flex-grow-0 justify-center">
            <v-icon
              small
              class="mr-2"
              color="primary"
              @click="onShowEditDialog(item)"
              v-if="canEdit"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              small
              class="mr-2"
              color="primary"
              @click="onShowDeleteDialog(item)"
              v-if="canEdit"
            >
              mdi-close-thick
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <AddExternalContractDialog
        :visible.sync="showAddDialog"
        :types="types"
        :contacts="contacts"
        @confirmed="onAddExternalContract"
      ></AddExternalContractDialog>

      <EditExternalContractDialog
        :visible.sync="showEditDialog"
        :types="types"
        :contacts="contacts"
        @confirmed="onEditExternalContract"
        :current="current"
      ></EditExternalContractDialog>

      <!-- dialogue de confirmation de suppression -->
      <v-dialog v-model="showDeleteDialog" width="400px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirmer la suppression</span>
          </v-card-title>

          <v-card-text>Voulez-vous supprimer ce contrat externe?</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showDeleteDialog = false" class="btn">Annuler</v-btn>
            <v-btn
              outlined
              color="primary"
              class="mx-2 my-2 px-4 btn"
              @click="confirmDeleteExternalContract"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- afficher des messages -->
      <v-snackbar
        v-model="snackbarVisible"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        :left="snackbarLeft"
        :right="snackbarRight"
        :top="snackbarTop"
        :bottom="snackbarBottom"
        >{{ snackbarMessage }}</v-snackbar
      >
    </v-container>
  </div>
</template>

<script>
import { roleCanEdit } from "@/service/role_service.js";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AddExternalContractDialog from "./dialogs/AddExternalContractDialog.vue";
import EditExternalContractDialog from "./dialogs/EditExternalContractDialog.vue";

import { LegalStructureExternalContractService } from "@/service/etablishment/legal_structure_external_contract_service.js";
import { ExternalContractTypesService } from "@/service/etablishment/external_contract_types_service.js";
import { ExternalContractContactService } from "@/service/etablishment/external_contract_contact_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "ExternalContractsPanel",
  components: { AddExternalContractDialog, EditExternalContractDialog },

  mixins: [SnackBarMixin],
  props: {
    legalStructureId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      /**indicateur de chargement des données */
      loading: false,

      /** est-ce que l'utilisateur peut éditer la ressource */
      canEdit: false,

      /** la liste des contrats */
      contracts: [],

      /**les colonnes à afficher */
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "contractType.label",
        },
        {
          text: "Nom contact",
          align: "start",
          sortable: true,
          value: "contractContact.name",
        },
        {
          text: "Adresse contact",
          align: "start",
          sortable: true,
          value: "contractContact.address",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ],

      /**le contrat sélectionné pour l'action : édition/suppression */
      selected: null,

      /**afficher le dialog de confirmation */
      showDeleteDialog: false,

      /**afficher le dialog d'édition' */
      showEditDialog: false,

      /**afficher le dialog d'édition' */
      showAddDialog: false,

      /**le service */
      service: null,

      /**le service d'accès aux types de contrats externes */
      serviceTypes: null,

      /**le service d'accès aux contacts des contrats externes */
      serviceContacts: null,

      /**les types de contrats */
      types: [],

      /**les contacts */
      contacts: [],
    };
  },
  methods: {
    /**L'utilisateur a appuyé sur le bouton 'AJOUTER UN CONTRAT', on affiche le dialog de création */
    onShowAddDialog() {
      this.showAddDialog = true;
    },
    /**fonction pour déterminer le nom du slot du v-data-table. sinon cela provoque une erreur eslint. */
    getItemActions() {
      return `item.actions`;
    },
    /**L'utilisateur a appuyé sur le bouton 'éditer', on affiche le dialog d'édition.*/
    onShowEditDialog(item) {
      this.selected = item;

      this.showEditDialog = true;
    },
    /**L'utilisateur a appuyé sur le bouton 'supprimer', on affiche le dialog de suppression.*/
    onShowDeleteDialog(item) {
      //on positionne l'item à supprimer
      this.selected = item;

      //on affiche le dialog de confirmation
      this.showDeleteDialog = true;
    },
    /**L'utilisateur confirme la suppression du contrat externe */
    async confirmDeleteExternalContract() {
      //la suppression est confirmée, on ferme le dialog
      this.showDeleteDialog = false;

      try {
        await this.service.removeContract(
          this.legalStructureId,
          this.selected.id
        );
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "suppression des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }

      this.load();
    },
    /**Chargement des contrats externes de la structure juridique */
    async load() {
      try {
        this.loading = true;
        if (this.legalStructureId) {
          let list = await this.service.getView(this.legalStructureId);

          this.contracts = list;

          this.types = await this.serviceTypes.getAll();

          this.contacts = await this.serviceContacts.getAll();
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    async onAddExternalContract(item) {
      try {
        await this.service.addContract(
          this.legalStructureId,
          item.type.id,
          item.contact.id
        );
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajout des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }

      this.load();
    },

    async onEditExternalContract(item) {
      try {
        await this.service.updateContract(
          this.legalStructureId,
          item.contractId,
          item.type.id,
          item.contact.id
        );
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "mise à jour des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }

      this.load();
    },
  },
  computed: {
    current() {
      if (this.selected && this.showEditDialog) {
        return {
          contractId: this.selected.id,
          type: this.selected.contractType,
          contact: this.selected.contractContact,
        };
      } else return null;
    },
  },
  mounted() {
    this.canEdit = roleCanEdit();

    this.service = new LegalStructureExternalContractService(
      this.$api.getLegalStructureExternalContractApi()
    );

    this.serviceTypes = new ExternalContractTypesService(
      this.$api.getExternalContractTypesApi()
    );

    this.serviceContacts = new ExternalContractContactService(
      this.$api.getExternalContractContactApi()
    );

    this.load();
  },
  watch: {},
};
</script>

<style>
</style>

