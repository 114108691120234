var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Ajouter un actionnaire, membre ou adhérent"),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: 5,
                        justify: "center",
                        "align-self": "center",
                        align: "start",
                      },
                    },
                    [
                      _c("div", { staticClass: "label-field capitalize" }, [
                        _vm._v(" actionnaire, membre ou adhérent "),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: 7,
                        "align-self": "center",
                        align: "start",
                      },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.availables,
                          "item-text": "name",
                          "return-object": "",
                          "no-data-text": "",
                        },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: 5,
                        justify: "center",
                        "align-self": "center",
                        align: "start",
                      },
                    },
                    [
                      _c("div", { staticClass: "label-field capitalize" }, [
                        _vm._v("pourcentage"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: 7,
                        "align-self": "center",
                        align: "start",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: "pourcentage",
                          dense: "",
                          type: "number",
                          rules: [_vm.rules.range0to100],
                        },
                        model: {
                          value: _vm.percent,
                          callback: function ($$v) {
                            _vm.percent = $$v
                          },
                          expression: "percent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 px-4 btn",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.abort()
                    },
                  },
                },
                [_vm._v("Annuler")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 px-4 btn",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    disabled: !_vm.canSave,
                  },
                  on: { click: _vm.confirm },
                },
                [_vm._v("Ajouter")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }