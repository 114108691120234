import { areTheSame } from "../tools/string_tool";

/**
 * comparer deux adresses : retourne true si les deux adresses sont identiques
 * @param {*} address
 * @param {*} other
 */
export function compareAddresses(address, other) {
  if (!areTheSame(address.address, other.address)) return false;

  if (!areTheSame(address.address2, other.address2)) return false;

  if (!areTheSame(address.postalCode, other.postalCode)) return false;

  if (!areTheSame(address.city, other.city)) return false;

  if (!areTheSame(address.latitude, other.latitude)) return false;

  if (!areTheSame(address.longitude, other.longitude)) return false;

  return true;
}
