var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              attrs: { flat: "", tile: "" },
            },
            [
              _c("Workflow", {
                attrs: {
                  width: "600",
                  height: "180",
                  steps: _vm.workflowSteps,
                  currentStep: _vm.workflowIndex,
                  labelWidth: 200,
                  lineWidth: 140,
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("TitleAndReturnComponent", {
                        attrs: { title: "Structure juridique" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [_c("WarningEditComponent")],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            xl: "6",
                            lg: "8",
                            md: "8",
                            sm: "10",
                            xs: "12",
                          },
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", active: _vm.loading },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v("Détail de la structure juridique"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("InputFieldComponent", {
                        attrs: { label: "nom digital" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "nom digital",
                                    dense: "",
                                  },
                                  on: { blur: _vm.digitalNameFieldOut },
                                  model: {
                                    value: _vm.digitalName,
                                    callback: function ($$v) {
                                      _vm.digitalName = $$v
                                    },
                                    expression: "digitalName",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "nom abrégé" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "nom abrégé",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.shortName,
                                    callback: function ($$v) {
                                      _vm.shortName = $$v
                                    },
                                    expression: "shortName",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "nature" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.types,
                                    "no-data-text": "aucune nature",
                                    "return-object": "",
                                  },
                                  model: {
                                    value: _vm.type,
                                    callback: function ($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "nom complet" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "nom complet",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.fullName,
                                    callback: function ($$v) {
                                      _vm.fullName = $$v
                                    },
                                    expression: "fullName",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "statut" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.allStatus,
                                    "no-data-text": "aucun statut",
                                    "return-object": "",
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function ($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "siège social" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.establishments,
                                    "item-text": "digitalName",
                                    "item-value": "id",
                                    "no-data-text": "aucun siège social",
                                  },
                                  model: {
                                    value: _vm.headOfficeId,
                                    callback: function ($$v) {
                                      _vm.headOfficeId = $$v
                                    },
                                    expression: "headOfficeId",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "adresse / rue" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: { placeholder: "rue", dense: "" },
                                  model: {
                                    value: _vm.addressRoad,
                                    callback: function ($$v) {
                                      _vm.addressRoad = $$v
                                    },
                                    expression: "addressRoad",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "adresse / complément d'adresse" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "complément",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.addressRoad2,
                                    callback: function ($$v) {
                                      _vm.addressRoad2 = $$v
                                    },
                                    expression: "addressRoad2",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "adresse / code postal" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "code postal",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.addressPostalCode,
                                    callback: function ($$v) {
                                      _vm.addressPostalCode = $$v
                                    },
                                    expression: "addressPostalCode",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "adresse / ville" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: { placeholder: "ville", dense: "" },
                                  model: {
                                    value: _vm.addressCity,
                                    callback: function ($$v) {
                                      _vm.addressCity = $$v
                                    },
                                    expression: "addressCity",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "adresse / latitude" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "latitude",
                                    dense: "",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.addressLatitude,
                                    callback: function ($$v) {
                                      _vm.addressLatitude = $$v
                                    },
                                    expression: "addressLatitude",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "adresse / longitude" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "longitude",
                                    dense: "",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.addressLongitude,
                                    callback: function ($$v) {
                                      _vm.addressLongitude = $$v
                                    },
                                    expression: "addressLongitude",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "SIREN" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: { placeholder: "siren", dense: "" },
                                  model: {
                                    value: _vm.siren,
                                    callback: function ($$v) {
                                      _vm.siren = $$v
                                    },
                                    expression: "siren",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "RCS" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: { placeholder: "rcs", dense: "" },
                                  model: {
                                    value: _vm.rcs,
                                    callback: function ($$v) {
                                      _vm.rcs = $$v
                                    },
                                    expression: "rcs",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "APE" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: { placeholder: "ape", dense: "" },
                                  model: {
                                    value: _vm.ape,
                                    callback: function ($$v) {
                                      _vm.ape = $$v
                                    },
                                    expression: "ape",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "représentant légal" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.legalRepresentatives,
                                    "return-object": "",
                                    "search-input":
                                      _vm.searchLegalRepresentative,
                                    "no-data-text": "aucun représentant légal",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.searchLegalRepresentative = $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.searchLegalRepresentative = $event
                                    },
                                  },
                                  model: {
                                    value: _vm.legalRepresentative,
                                    callback: function ($$v) {
                                      _vm.legalRepresentative = $$v
                                    },
                                    expression: "legalRepresentative",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "qualité du représentant légal" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.allRepresentativeStatus,
                                    "return-object": "",
                                    "no-data-text":
                                      "aucune qualité de représentant légal",
                                  },
                                  model: {
                                    value: _vm.legalRepresentativeStatus,
                                    callback: function ($$v) {
                                      _vm.legalRepresentativeStatus = $$v
                                    },
                                    expression: "legalRepresentativeStatus",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: {
                          label: "capital ou fond associatif/mutualiste",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder:
                                      "capital ou fond associatif/mutualiste",
                                    dense: "",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.capital,
                                    callback: function ($$v) {
                                      _vm.capital = $$v
                                    },
                                    expression: "capital",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "lien acte de cession" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "lien acte de cession",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.cessionActLink,
                                    callback: function ($$v) {
                                      _vm.cessionActLink = $$v
                                    },
                                    expression: "cessionActLink",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "lien jugement de cession" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "lien jugement de cession",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.cessionJudgmentLink,
                                    callback: function ($$v) {
                                      _vm.cessionJudgmentLink = $$v
                                    },
                                    expression: "cessionJudgmentLink",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "gestionnaire FINESS" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "gestionnaire FINESS",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.managerFiness,
                                    callback: function ($$v) {
                                      _vm.managerFiness = $$v
                                    },
                                    expression: "managerFiness",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "Nom du commissaire aux comptes" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder:
                                      "Nom du commissaire aux comptes",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.statutoryAuditorName,
                                    callback: function ($$v) {
                                      _vm.statutoryAuditorName = $$v
                                    },
                                    expression: "statutoryAuditorName",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: {
                          label: "nombre de titres ou de droits de vote",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder:
                                      "Nombre de titres ou de droits de vote",
                                    dense: "",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.numberOfCompanySecurities,
                                    callback: function ($$v) {
                                      _vm.numberOfCompanySecurities = $$v
                                    },
                                    expression: "numberOfCompanySecurities",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: {
                          label: "ID drive Procès-verbal Assemblée générale",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder:
                                      "ID drive Procès-verbal de l'Assemblée générale",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.idDriveMinutesGeneralMeeting,
                                    callback: function ($$v) {
                                      _vm.idDriveMinutesGeneralMeeting = $$v
                                    },
                                    expression: "idDriveMinutesGeneralMeeting",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: { label: "Indice d'égalité professionnelle" },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder:
                                      "Indice d'égalité professionnelle",
                                    dense: "",
                                    type: "number",
                                    min: "0",
                                    rules: [
                                      _vm.rules.positive,
                                      _vm.rules.decimal,
                                    ],
                                  },
                                  on: { blur: _vm.numberWithMaxTwoDecimal },
                                  model: {
                                    value: _vm.professionalEqualityIndex,
                                    callback: function ($$v) {
                                      _vm.professionalEqualityIndex = $$v
                                    },
                                    expression: "professionalEqualityIndex",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("InputFieldComponent", {
                        attrs: {
                          label: "TVA intracommunautaire",
                          divider: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "TVA intracommunautaire",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.intraCommunityVAT,
                                    callback: function ($$v) {
                                      _vm.intraCommunityVAT = $$v
                                    },
                                    expression: "intraCommunityVAT",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2 my-2 px-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: !_vm.hasChanged,
                              },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Enregistrer ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "Les actionnaires, membres ou adhérents"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center",
                        },
                        [
                          _vm.canEdit
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4 btn",
                                  attrs: { outlined: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showAddStockholderDialog = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " Ajouter un actionnaire, membre ou adhérent "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("StockholdersPanel", {
                        attrs: { stockholders: _vm.stockholders },
                        on: {
                          delete: _vm.deleteStockholder,
                          edit: _vm.editStockholder,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [_c("div", [_vm._v("Les contrats")])]
                          ),
                        ],
                        1
                      ),
                      _c("ExternalContractsPanel", {
                        attrs: { legalStructureId: _vm.entityId },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [_c("div", [_vm._v("Les événements")])]
                          ),
                        ],
                        1
                      ),
                      _c("EventsPanel", {
                        attrs: { legalStructureId: _vm.entityId },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "my-4",
                      attrs: { flat: "", outlined: "", id: "eventid" },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v("Les établissements rattachés"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        attrs: {
                          locale: "fr-FR",
                          headers: _vm.headerEstabs,
                          items: _vm.estabs,
                          "no-data-text": "aucun établissemant",
                          "disable-pagination": "",
                          "hide-default-footer": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage) + " ")]
      ),
      _c("AddStockholderDialog", {
        attrs: {
          visible: _vm.showAddStockholderDialog,
          used: _vm.stockholdersIds,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAddStockholderDialog = $event
          },
          confirmed: _vm.addStockholder,
        },
      }),
      _c(
        "v-layout",
        { staticClass: "fab-container", attrs: { column: "" } },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll",
                },
              ],
              staticClass: "mx-2 my-2",
              attrs: {
                fab: "",
                elevation: "0",
                disabled: !_vm.canScrollToTop,
                color: "primary",
              },
              on: { click: _vm.toTop },
            },
            [
              _c("v-icon", { attrs: { disabled: !_vm.canScrollToTop } }, [
                _vm._v("mdi-chevron-up"),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll",
                },
              ],
              staticClass: "mx-2 my-1",
              attrs: {
                fab: "",
                elevation: "0",
                disabled: !_vm.canScrollToBottom,
                color: "primary",
              },
              on: { click: _vm.toBottom },
            },
            [
              _c("v-icon", { attrs: { disabled: !_vm.canScrollToBottom } }, [
                _vm._v("mdi-chevron-down"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }