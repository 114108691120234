<template>
  <v-dialog v-model="show" width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">Modifier un contrat</span>
      </v-card-title>

      <!-- le type -->
      <v-card-text>
        <v-row no-gutters align="center">
          <v-col :cols="5" justify="center" align-self="center" align="start">
            <div class="label-field capitalize">type</div>
          </v-col>

          <!-- la donnée associée -->
          <v-col :cols="7" align-self="center" align="start">
            <v-autocomplete
              return-object
              no-data-text="aucune donnée"
              :items="types"
              v-model="type"
              item-text="label"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <!-- le contact -->
      <v-card-text>
        <v-row no-gutters align="center" class="mb-3">
          <v-col :cols="5" justify="center" align-self="start" align="start">
            <div class="label-field capitalize mt-5">nom contact</div>
          </v-col>

          <!-- la donnée associée -->
          <v-col :cols="7" align-self="center" align="start">
            <v-autocomplete
              :items="contacts"
              v-model="contact"
              item-text="name"
              return-object
              no-data-text="aucun contact"
            ></v-autocomplete>
            <div v-if="contact">{{ contact.address }}</div>
            <div v-else></div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="abort()" class="btn">Annuler</v-btn>
        <v-btn
          outlined
          color="primary"
          class="mx-2 my-2 px-4 btn"          
          @click="confirm"
          :disabled="!canSave"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditExternalContractDialog",
  components: {},

  mixins: [],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    /**les types de contrats externes de la structure juridique */
    types: {
      type: Array,
      default: function () {
        return [];
      },
    },
    /**les contacts de contrats externes de la structure juridique */
    contacts: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**les valeurs courante du contrat */
    current: {
      type: Object,
      default: function () {
        return { type: null, contact: null, contractId: null };
      },
    },
  },
  data() {
    return {
      show: this.visible,

      /**le type sélectionné */
      type: null,

      /**le contact sélectionné */
      contact: null,
    };
  },
  methods: {
    /**l'action n'est pas confirmée. on ferme le dialog.*/
    abort() {
      this.close();
    },

    /**l'action est confirmée.*/
    confirm() {
      this.$emit("confirmed", {
        contractId: this.current.contractId,
        type: this.type,
        contact: this.contact,
      });
      this.close();
    },

    /**Fermer le dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },
  },
  computed: {
    canSave() {
      //il faut un type de contrat
      if (!this.type) {
        // console.log("pas de type");
        return false;
      }

      if (!this.contact) {
        // console.log("pas de contact");
        return false;
      }

      if (!this.current) {
        // console.log("pas de current");
        return false;
      }

      // je ne comprends pas pourquoi le test sur le contact ne fonctionne pas :(
      //   (canSave n'est pas appelée sur une modification de 'contact')
      //   if (this.contact.id == this.current.contact.id) return false;
      //   if (this.type.id == this.current.type.id) return false;

      return true;
    },
  },
  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.type = null;
        this.contact = null;
        this.$emit("update:visible", false);
      }
    },
    current(value) {
      if (value) {
        this.type = value.type;
        this.contact = value.contact;
      } else {
        this.type = null;
        this.contact = null;
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>

