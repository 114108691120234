<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Structure juridique" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de la structure juridique</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <InputFieldComponent label="nom digital">
              <template v-slot:value>
                <v-text-field
                  placeholder="nom digital"
                  dense
                  v-model="digitalName"
                  @blur="digitalNameFieldOut"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- le nom abrégé -->
            <InputFieldComponent label="nom abrégé">
              <template v-slot:value>
                <v-text-field
                  placeholder="nom abrégé"
                  dense
                  v-model="shortName"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- la nature -->
            <InputFieldComponent label="nature">
              <template v-slot:value>
                <v-autocomplete
                  :items="types"
                  v-model="type"
                  no-data-text="aucune nature"
                  return-object
                ></v-autocomplete>
              </template>
            </InputFieldComponent>

            <!-- la nature -->
            <InputFieldComponent label="nom complet">
              <template v-slot:value>
                <v-text-field
                  placeholder="nom complet"
                  dense
                  v-model="fullName"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- le statut -->
            <InputFieldComponent label="statut">
              <template v-slot:value>
                <v-autocomplete
                  :items="allStatus"
                  v-model="status"
                  no-data-text="aucun statut"
                  return-object
                ></v-autocomplete>
              </template>
            </InputFieldComponent>

            <!-- le siège social -->
            <InputFieldComponent label="siège social">
              <template v-slot:value>
                <v-autocomplete
                  :items="establishments"
                  v-model="headOfficeId"
                  item-text="digitalName"
                  item-value="id"
                  no-data-text="aucun siège social"
                ></v-autocomplete>
              </template>
            </InputFieldComponent>

            <!-- l'adresse / rue -->
            <InputFieldComponent label="adresse / rue">
              <template v-slot:value>
                <v-text-field
                  placeholder="rue"
                  dense
                  v-model="addressRoad"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- l'adresse / rue -->
            <InputFieldComponent label="adresse / complément d'adresse">
              <template v-slot:value>
                <v-text-field
                  placeholder="complément"
                  dense
                  v-model="addressRoad2"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- l'adresse / code postal -->
            <InputFieldComponent label="adresse / code postal">
              <template v-slot:value>
                <v-text-field
                  placeholder="code postal"
                  dense
                  v-model="addressPostalCode"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- l'adresse / ville -->
            <InputFieldComponent label="adresse / ville">
              <template v-slot:value>
                <v-text-field
                  placeholder="ville"
                  dense
                  v-model="addressCity"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- l'adresse / latitude -->
            <InputFieldComponent label="adresse / latitude">
              <template v-slot:value>
                <v-text-field
                  placeholder="latitude"
                  dense
                  v-model="addressLatitude"
                  type="number"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- l'adresse / longitude -->
            <InputFieldComponent label="adresse / longitude">
              <template v-slot:value>
                <v-text-field
                  placeholder="longitude"
                  dense
                  v-model="addressLongitude"
                  type="number"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- SIREN -->
            <InputFieldComponent label="SIREN">
              <template v-slot:value>
                <v-text-field
                  placeholder="siren"
                  dense
                  v-model="siren"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- RCS -->
            <InputFieldComponent label="RCS">
              <template v-slot:value>
                <v-text-field
                  placeholder="rcs"
                  dense
                  v-model="rcs"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- APE -->
            <InputFieldComponent label="APE">
              <template v-slot:value>
                <v-text-field
                  placeholder="ape"
                  dense
                  v-model="ape"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- REPRÉSENTANT LÉGAL -->
            <InputFieldComponent label="représentant légal">
              <template v-slot:value>
                <v-autocomplete
                  :items="legalRepresentatives"
                  v-model="legalRepresentative"
                  return-object
                  :search-input.sync="searchLegalRepresentative"
                  no-data-text="aucun représentant légal"
                ></v-autocomplete>
              </template>
            </InputFieldComponent>

            <!-- QUALITÉ DU REPRÉSENTANT LÉGAL -->
            <InputFieldComponent label="qualité du représentant légal">
              <template v-slot:value>
                <v-autocomplete
                  :items="allRepresentativeStatus"
                  v-model="legalRepresentativeStatus"
                  return-object
                  no-data-text="aucune qualité de représentant légal"
                ></v-autocomplete>
              </template>
            </InputFieldComponent>

            <!-- CAPITAL -->
            <InputFieldComponent label="capital ou fond associatif/mutualiste">
              <template v-slot:value>
                <v-text-field
                  placeholder="capital ou fond associatif/mutualiste"
                  dense
                  v-model="capital"
                  type="number"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- LIEN ACTE DE SESSION -->
            <InputFieldComponent label="lien acte de cession">
              <template v-slot:value>
                <v-text-field
                  placeholder="lien acte de cession"
                  dense
                  v-model="cessionActLink"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- LIEN JUGEMENT DE SESSION -->
            <InputFieldComponent label="lien jugement de cession">
              <template v-slot:value>
                <v-text-field
                  placeholder="lien jugement de cession"
                  dense
                  v-model="cessionJudgmentLink"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- FINESS GESTIONNAIRE -->
            <InputFieldComponent label="gestionnaire FINESS">
              <template v-slot:value>
                <v-text-field
                  placeholder="gestionnaire FINESS"
                  dense
                  v-model="managerFiness"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- statutoryAuditorName -->
            <InputFieldComponent label="Nom du commissaire aux comptes">
              <template v-slot:value>
                <v-text-field
                  placeholder="Nom du commissaire aux comptes"
                  dense
                  v-model="statutoryAuditorName"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- numberOfCompanySecurities -->
            <InputFieldComponent label="nombre de titres ou de droits de vote">
              <template v-slot:value>
                <v-text-field
                  placeholder="Nombre de titres ou de droits de vote"
                  dense
                  v-model="numberOfCompanySecurities"
                  type="number"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- idDriveMinutesGeneralMeeting -->
            <InputFieldComponent
              label="ID drive Procès-verbal Assemblée générale"
            >
              <template v-slot:value>
                <v-text-field
                  placeholder="ID drive Procès-verbal de l'Assemblée générale"
                  dense
                  v-model="idDriveMinutesGeneralMeeting"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- professionalEqualityIndex -->
            <InputFieldComponent label="Indice d'égalité professionnelle">
              <template v-slot:value>
                <v-text-field
                  placeholder="Indice d'égalité professionnelle"
                  dense
                  v-model="professionalEqualityIndex"
                  type="number"
                  min="0"
                  @blur="numberWithMaxTwoDecimal"
                  :rules="[rules.positive, rules.decimal]"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- TVA intracommunautaire -->
            <InputFieldComponent
              label="TVA intracommunautaire"
              :divider="false"
            >
              <template v-slot:value>
                <v-text-field
                  placeholder="TVA intracommunautaire"
                  dense
                  v-model="intraCommunityVAT"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="success" @click="test">test</v-btn> -->
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- les actionnaires -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Les actionnaires, membres ou adhérents</div>
              </v-row>
            </v-card-title>
            <div class="d-flex justify-space-between align-center">
              <v-btn
                class="ml-4 btn"
                outlined
                color="primary"
                v-if="canEdit"
                @click="showAddStockholderDialog = true"
              >
                Ajouter un actionnaire, membre ou adhérent
              </v-btn>
            </div>
            <StockholdersPanel
              :stockholders="stockholders"
              @delete="deleteStockholder"
              @edit="editStockholder"
            ></StockholdersPanel>
          </v-card>

          <!-- les contrats -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Les contrats</div>
              </v-row>
            </v-card-title>
            <ExternalContractsPanel
              :legalStructureId="entityId"
            ></ExternalContractsPanel>
          </v-card>

          <!-- les événements -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Les événements</div>
              </v-row>
            </v-card-title>
            <EventsPanel :legalStructureId="entityId"></EventsPanel>
          </v-card>

          <!-- les établissements -->
          <v-card flat outlined class="my-4" id="eventid">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Les établissements rattachés</div>
              </v-row>
            </v-card-title>

            <v-data-table
              locale="fr-FR"
              :headers="headerEstabs"
              :items="estabs"
              no-data-text="aucun établissemant"
              disable-pagination
              hide-default-footer
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}
    </v-snackbar>

    <AddStockholderDialog
      :visible.sync="showAddStockholderDialog"
      :used="stockholdersIds"
      @confirmed="addStockholder"
    ></AddStockholderDialog>

    <v-layout column class="fab-container">
      <v-btn
        class="mx-2 my-2"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToTop"
        color="primary"
        @click="toTop"
      >
        <v-icon :disabled="!canScrollToTop">mdi-chevron-up</v-icon>
      </v-btn>

      <v-btn
        class="mx-2 my-1"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToBottom"
        @click="toBottom"
        color="primary"
      >
        <v-icon :disabled="!canScrollToBottom">mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import StockholdersPanel from "./StockholdersPanel.vue";
import AddStockholderDialog from "./dialogs/AddStockholderDialog.vue";
import ExternalContractsPanel from "./ExternalContractsPanel.vue";
import EventsPanel from "./EventsPanel.vue";

import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";
import { LegalStructureTypeService } from "@/service/etablishment/legal_structure_type_service.js";
import { LegalStructureStatusService } from "@/service/etablishment/legal_structure_status_service.js";
import { LegalRepresentativeStatusService } from "@/service/etablishment/legal_representative_status_service.js";
import { LegalStructureStockholderService } from "@/service/etablishment/legal_structure_stockholder_service.js";
import { UsersService } from "@/service/user/dg_user_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";

import { areTheSames, isNullOrEmpty, trimString } from "@/tools/string_tool.js";
import { compareAddresses } from "@/service/address_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import { numberWithMaxTwoDecimal, numberOfDecimal } from "@/tools/number_tool";

export default {
  name: "EditLegalStructure",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    InputFieldComponent,
    StockholdersPanel,
    AddStockholderDialog,
    ExternalContractsPanel,
    EventsPanel,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceLegalStructure: null,

      serviceLegalStructureTypes: null,

      serviceLegalStructureStatus: null,

      serviceUsers: null,

      serviceLegalRepresentativeStatus: null,

      serviceLegalStructureStockholder: null,

      serviceEstablishments: null,
      serviceActivities: null,
      serviceMetiers: null,

      /** l'identifiant à éditer */
      entityId: +this.$route.params.id,

      /**La donnée originale */
      source: null,

      /**le nom digital */
      digitalName: null,

      /**la nature de la structure juridique */
      type: null,

      /**le nom complet de la structure juridique */
      fullName: null,

      /**le siren de la structure juridique */
      siren: null,

      /**le nom abrégé de la structure juridique */
      shortName: null,

      /**le statut de la structure juridique */
      status: null,

      /**L'adresse de la structure juridique */
      addressRoad: null,

      addressRoad2: null,

      addressPostalCode: null,

      addressCity: null,

      addressLatitude: null,

      addressLongitude: null,

      ape: null,

      capital: null,

      legalRepresentative: null,

      legalRepresentativeUuid: null,

      legalRepresentativeStatus: null,

      cessionActLink: null,

      cessionJudgmentLink: null,

      managerFiness: null,

      rcs: null,

      statutoryAuditorName: null,

      numberOfCompanySecurities: null,

      idDriveMinutesGeneralMeeting: null,

      professionalEqualityIndex: null,

      intraCommunityVAT: null,

      establishments: [],

      headOfficeId: null,

      types: [],

      allStatus: [],

      lastSearchTegalRepresentativeTimestamp: Date.now(),

      legalRepresentatives: [],

      searchLegalRepresentative: null,

      allRepresentativeStatus: [],

      /**Boutons pour scroller tout en bas et tout en haut  */
      canScrollToTop: false,
      canScrollToBottom: true,

      /**les actionnaires */
      stockholders: [],
      /** les établissements rattachés */
      estabs: [],
      /** les activitées de la bdd */
      activities: [],
      /** Les métiers de la bdd */
      metiers: [],

      /**afficher le dialogue d'ajout d'actionnaire */
      showAddStockholderDialog: false,

      /** Les règles de saisie */
      rules: {
        positive: (value) => this.positiveRules(value),
        decimal: (value) => this.decimalMax(value),
      },

      /** La liste des tables de la section établissement rattachés */
      headerEstabs: [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
        },
        {
          text: "Métier",
          align: "start",
          sortable: true,
          value: "metierName",
        },
        {
          text: "Activités",
          align: "start",
          sortable: true,
          value: "activitiesName",
        },
        {
          text: "Statut",
          sortable: false,
          align: "center",
          value: "status",
        },
        {
          text: "Nom commercial",
          sortable: false,
          align: "center",
          value: "commercialName",
        },
      ],
    };
  },
  methods: {
    numberWithMaxTwoDecimal() {
      this.professionalEqualityIndex = numberWithMaxTwoDecimal(
        this.professionalEqualityIndex
      );
    },

    async load() {
      try {
        this.loading = true;

        this.stockholders = [];

        //chargement des natures de structures juridiques
        let types = await this.serviceLegalStructureTypes.getAll();
        this.types = types.map((type) => type.label);

        let allStatus = await this.serviceLegalStructureStatus.getAll();

        this.allStatus = allStatus.map((s) => s.label);

        let allRepresentativeStatus =
          await this.serviceLegalRepresentativeStatus.getAll();

        this.allRepresentativeStatus = allRepresentativeStatus.map(
          (s) => s.label
        );

        let entity = await this.serviceLegalStructure.getById(this.entityId);

        this.legalRepresentatives = [];
        console.log("entity: " + JSON.stringify(entity));
        if (entity.legalRepresentativeUuid) {
          let user = await this.serviceUsers.getByUuid(
            entity.legalRepresentativeUuid
          );

          this.legalRepresentatives.push({
            text: user.gsuiteaccount.email,
            value: user,
          });

          this.legalRepresentative = this.legalRepresentatives[0];
        }

        //le siège social
        this.establishments =
          await this.serviceEstablishments.getDigitalNames();

        this.headOfficeId = entity.headOfficeId;

        this.source = JSON.parse(JSON.stringify(entity));

        //chargement des actionnaires de la structure
        await this.loadStockholders();
        // chargement des établissement attachés
        await this.loadAttachedEstablishment();
        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    // Chargement des données pour les établissement rattachés
    async loadAttachedEstablishment() {
      // Récupération de l'ensemble des établissements rattachés à la structure juridique
      const estabsAttached =
        await this.serviceLegalStructure.getAllAttachedEstablishment(
          this.entityId
        );
      // Récupération de l'ensemble des activités de la bdd
      this.activities = await this.serviceActivities.getActivities();
      // Récupération de l'ensemble des métiers de la bdd
      this.metiers = await this.serviceMetiers.getAll();

      // Association entre établissement, activités et métier
      // Pour chaque établissement, ajout de ses noms d'activités et des nom de métiers
      estabsAttached.forEach((e) => {
        // Récupération des noms d'activités
        e.activitiesName = e.activityIds
          .map((a) => {
            return this.activities.find((ac) => ac.id == a).name;
          })
          .join(", ");

        e.metierName = this.metiers.find((m) => e.metierId == m.id).name;
      });
      this.estabs = estabsAttached;
    },

    async loadStockholders() {
      let list =
        await this.serviceLegalStructureStockholder.getViewByLegalStructure(
          this.entityId
        );

      list.forEach((stockholder) => {
        if (stockholder.percent) {
          stockholder.labelPercent = stockholder.percent + "%";
        } else {
          stockholder.labelPercent = "";
        }
      });

      this.stockholders = list;
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.digitalName = this.source.digitalName;
      this.shortName = this.source.shortName;
      this.type = this.source.type;
      this.fullName = this.source.fullName;
      this.status = this.source.status;

      if (this.source.address) {
        this.addressRoad = this.source.address.address;
        this.addressRoad2 = this.source.address.address2;
        this.addressPostalCode = this.source.address.postalCode;
        this.addressCity = this.source.address.city;
        this.addressLatitude = this.source.address.latitude;
        this.addressLongitude = this.source.address.longitude;
      }

      this.siren = this.source.siren;

      this.rcs = this.source.rcs;

      this.ape = this.source.ape;

      this.legalRepresentativeUuid = this.source.legalRepresentativeUuid;

      this.legalRepresentativeStatus = this.source.legalRepresentativeStatus;

      this.capital = this.source.capital;

      this.cessionActLink = this.source.cessionActLink;
      this.cessionJudgmentLink = this.source.cessionJudgmentLink;
      this.managerFiness = this.source.managerFiness;

      this.statutoryAuditorName = this.source.statutoryAuditorName;
      this.numberOfCompanySecurities = this.source.numberOfCompanySecurities;
      this.idDriveMinutesGeneralMeeting =
        this.source.idDriveMinutesGeneralMeeting;
      this.idDriveMinutesGeneralMeeting =
        this.source.idDriveMinutesGeneralMeeting;
      this.professionalEqualityIndex = this.source.professionalEqualityIndex;
      this.intraCommunityVAT = this.source.intraCommunityVAT;
    },

    async save() {
      // enregistrement de l'entity
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));

          // Ce champ n'est plus accepté par la route Put de miseà jour d'une structure juridique
          // de ce fait nous devons le supprimé car il est à ce jour présent dans l'objet récupéré lors du load.
          delete entity.legalRepresentativeId;

          entity.digitalName = this.label;

          entity.digitalName = this.digitalName;
          entity.shortName = this.shortName;
          entity.fullName = this.fullName;
          entity.siren = this.siren;
          entity.ape = this.ape;
          entity.capital = this.capital;
          entity.type = this.type;
          entity.status = this.status;

          if (
            !isNullOrEmpty(this.addressRoad) ||
            !isNullOrEmpty(this.addressPostalCode) ||
            !isNullOrEmpty(this.addressCity) ||
            this.addressLatitude ||
            this.addressLongitude
          ) {
            //il y a une adresse (au moins un début)
            entity.address = {
              address: this.addressRoad,
              address2: this.addressRoad2,
              postalCode: this.addressPostalCode,
              city: this.addressCity,
              latitude: Number(this.addressLatitude),
              longitude: Number(this.addressLongitude),
            };
          } else {
            entity.address = null;
          }

          if (this.legalRepresentative) {
            entity.legalRepresentativeUuid =
              this.legalRepresentative.value.uuid;
          } else {
            entity.legalRepresentativeUuid = null;
          }

          entity.legalRepresentativeStatus = this.legalRepresentativeStatus;
          entity.cessionActLink = this.cessionActLink;
          entity.cessionJudgmentLink = this.cessionJudgmentLink;
          entity.managerFiness = this.managerFiness;
          entity.rcs = this.rcs;
          entity.statutoryAuditorName = this.statutoryAuditorName;
          entity.numberOfCompanySecurities = this.numberOfCompanySecurities;
          entity.idDriveMinutesGeneralMeeting =
            this.idDriveMinutesGeneralMeeting;
          entity.idDriveMinutesGeneralMeeting =
            this.idDriveMinutesGeneralMeeting;
          entity.professionalEqualityIndex = this.professionalEqualityIndex
            ? this.professionalEqualityIndex
            : null;

          entity.intraCommunityVAT = this.intraCommunityVAT;

          entity.headOfficeId = this.headOfficeId;

          await this.serviceLegalStructure.update(entity);

          this.disableAlertQuit();
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement du type d'évènement d'une structure juridique: " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const currentPos = window.pageYOffset || e.target.scrollTop || 0;
      const bottom = document.body.scrollHeight - window.innerHeight;
      this.canScrollToTop = currentPos > bottom * 0.15;
      this.canScrollToBottom = currentPos < bottom * 0.85;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    toBottom() {
      this.$vuetify.goTo(document.body.scrollHeight);
    },
    async addStockholder(item) {
      await this.serviceLegalStructureStockholder.addStockholderToStructure(
        +this.entityId,
        item.stockholderId,
        item.percent
      );

      this.loadStockholders();
    },
    /**Supprimer l'actionnaire. itemId : identifiant de la relation */
    async deleteStockholder(itemId) {
      await this.serviceLegalStructureStockholder.removeStockholderFromStructure(
        +this.entityId,
        +itemId
      );

      this.loadStockholders();
    },
    async editStockholder(change) {
      await this.serviceLegalStructureStockholder.updateStockholderForStructure(
        +this.entityId,
        change.itemId,
        change.stockholderId,
        change.percent
      );

      this.loadStockholders();
    },
    async test() {
      console.log(JSON.stringify(this.legalRepresentativeUuid, null, 4));
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

    /** Règle de valeur strictement positive */
    positiveRules(value) {
      if (value) {
        if (parseFloat(value) < 0 || parseInt(value) < 0) {
          return "pas de valeur négative";
        }
      }
      return true;
    },

    decimalMax(value) {
      if (value && this.positiveRules(value) == true) {
        if (numberOfDecimal(value) > 2) {
          return "2 décimales maximum, votre nombre sera arrondie au centième";
        }
      }
      return true;
    },
  },
  computed: {
    completed() {
      if (!this.digitalName || this.digitalName == "") return false;

      //on vérifie que la latitude soit correctement renseignée
      if (!isNullOrEmpty(this.addressLatitude)) {
        if (isNaN(new Number(this.addressLatitude))) {
          return false;
        }
      }

      //on vérifie que la longitude soit correctement renseignée
      if (!isNullOrEmpty(this.addressLongitude)) {
        if (isNaN(new Number(this.addressLongitude))) {
          return false;
        }
      }

      //on vérifie que le capital soit correctement renseigné
      if (!isNullOrEmpty(this.capital)) {
        if (isNaN(new Number(this.capital))) {
          return false;
        }
      }

      return true;
    },
    hasChanged() {
      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'entité est modifié */
    entityHasChanged() {
      let changed = false;

      let compares = [];
      compares.push([this.source.digitalName, trimString(this.digitalName)]);
      compares.push([this.source.shortName, this.shortName]);
      compares.push([this.source.fullName, this.fullName]);
      compares.push([this.source.siren, this.siren]);
      compares.push([this.source.ape, this.ape]);
      compares.push([this.source.rcs, this.rcs]);
      compares.push([
        this.source.legalRepresentativeStatus,
        this.legalRepresentativeStatus,
      ]);
      compares.push([this.source.capital, this.capital]);

      compares.push([this.source.cessionActLink, this.cessionActLink]);
      compares.push([
        this.source.cessionJudgmentLink,
        this.cessionJudgmentLink,
      ]);
      compares.push([this.source.managerFiness, this.managerFiness]);
      compares.push([
        this.source.statutoryAuditorName,
        this.statutoryAuditorName,
      ]);
      compares.push([
        this.source.numberOfCompanySecurities,
        this.numberOfCompanySecurities,
      ]);
      compares.push([
        this.source.idDriveMinutesGeneralMeeting,
        this.idDriveMinutesGeneralMeeting,
      ]);
      compares.push([
        this.source.professionalEqualityIndex,
        this.professionalEqualityIndex,
      ]);
      compares.push([this.source.intraCommunityVAT, this.intraCommunityVAT]);

      if (!areTheSames(compares)) {
        changed = true;
      }

      if (this.source.type != this.type) {
        changed = true;
      }

      if (this.source.status != this.status) {
        changed = true;
      }

      let currentLegalRepresentativeUuid = this.legalRepresentative
        ? this.legalRepresentative.value.uuid
        : null;

      if (
        this.source.legalRepresentativeUuid != currentLegalRepresentativeUuid
      ) {
        changed = true;
      }

      //la source
      let address1 = {
        address: null,
        address2: null,
        postalCode: null,
        city: null,
        latitude: null,
        longitude: null,
      };

      if (this.source.address) {
        address1.address = this.source.address.address;
        address1.address2 = this.source.address.address2;
        address1.postalCode = this.source.address.postalCode;
        address1.city = this.source.address.city;
        address1.latitude = this.source.address.latitude;
        address1.longitude = this.source.address.longitude;
      }

      let address2 = {
        address: this.addressRoad,
        address2: this.addressRoad2,
        postalCode: this.addressPostalCode,
        city: this.addressCity,
        latitude: this.addressLatitude,
        longitude: this.addressLongitude,
      };
      if (!compareAddresses(address1, address2)) {
        changed = true;
      }

      if (this.source.headOfficeId != this.headOfficeId) {
        changed = true;
      }

      if (
        this.positiveRules(this.professionalEqualityIndex) != true ||
        this.decimalMax(this.professionalEqualityIndex) != true
      ) {
        changed = false;
      }

      return changed;
    },
    /**les identifiants des actionnaires */
    stockholdersIds() {
      return this.stockholders.map((e) => e.stockholder.id);
    },
  },
  watch: {
    searchLegalRepresentative(search) {
      if (this.loading) {
        //pas de recherche durant le chargement de la page
        return;
      }

      if (!search || search == "") {
        //le texte de recherche est vide, on resert

        this.legalRepresentative = null;
        this.legalRepresentatives = [];
        return;
      }
      if (search.length < 3) {
        this.legalRepresentatives = [];
        return;
      }
      let now = Date.now();
      this.lastSearchTegalRepresentativeTimestamp = now;

      //on lance la recherche par email
      this.serviceUsers
        .searchDgUserByEmail(search, now)
        .then((results) => {
          //on vérifie que l'on ne garde pas une réponse plus ancienne (à cause du traitement de la requête)
          if (results.ts < this.lastSearchTegalRepresentativeTimestamp) {
            //console.error("trop tard");
            return;
          }

          let users = [];
          results.users.forEach((user) => {
            users.push({ text: user.email, value: user });
          });

          // console.log(JSON.stringify(users, null, 4));

          this.legalRepresentatives = users;
        })
        .catch((err) => {
          console.error("problème lors de la recherche par email:" + err);
        });
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceLegalStructure = new LegalStructureService(
      this.$api.getLegalStructuresApi()
    );

    this.serviceLegalStructureTypes = new LegalStructureTypeService(
      this.$api.getLegalStructureTypeApi()
    );

    this.serviceLegalStructureStatus = new LegalStructureStatusService(
      this.$api.getLegalStructuresStatusApi()
    );

    this.serviceUsers = new UsersService(this.$api.getUsersApi());

    this.serviceLegalRepresentativeStatus =
      new LegalRepresentativeStatusService(
        this.$api.getLegalRepresentativeStatusApi()
      );

    this.serviceLegalStructureStockholder =
      new LegalStructureStockholderService(
        this.$api.getLegalStructureStockholderApi()
      );

    this.serviceEstablishments = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
.fab-container {
  margin: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
