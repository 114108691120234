var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        attrs: {
          locale: "fr-FR",
          headers: _vm.headers,
          items: _vm.stockholders,
          "no-data-text": "aucun actionnaire, membre ou adhérent",
          "disable-pagination": "",
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: _vm.getItemActions(),
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-0 justify-center" },
                    [
                      _vm.canEdit
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onShowEditDialog(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-pencil ")]
                          )
                        : _vm._e(),
                      _vm.canEdit
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onShowDeleteDialog(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-close-thick ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "400px" },
          model: {
            value: _vm.showDeleteDialog,
            callback: function ($$v) {
              _vm.showDeleteDialog = $$v
            },
            expression: "showDeleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Confirmer la suppression"),
                ]),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "Voulez-vous supprimer cet actionnaire, membre ou adhérent ?"
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteDialog = false
                        },
                      },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2 my-2 px-4 btn",
                      attrs: { outlined: "", color: "primary" },
                      on: { click: _vm.confirmDeleteStockholder },
                    },
                    [_vm._v("Supprimer")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditStockholderDialog", {
        attrs: {
          visible: _vm.showEditStockholderDialog,
          currentStockholderId: _vm.selectedStockholderId,
          currentPercent: _vm.selectedPercent,
          currentItemId: _vm.selectedItemId,
          used: _vm.stockholdersIds,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showEditStockholderDialog = $event
          },
          confirmed: _vm.confirmEditStockholder,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }