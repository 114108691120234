<template>
  <v-dialog v-model="show" width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">Ajouter un événement</span>
      </v-card-title>

      <!-- le type -->
      <v-card-text>
        <v-row no-gutters align="center">
          <!-- le type -->
          <v-col :cols="8" justify="center" align-self="center" align="start">
            <v-autocomplete
              return-object
              no-data-text="aucun type d'évènement"
              :items="types"
              v-model="type"
              item-text="label"
              placeholder="Type"
              hint="Choisir un type d'événement"
              persistent-hint
            ></v-autocomplete>
          </v-col>

          <!-- la date -->
          <v-col :cols="4" align-self="center" align="start">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center justify-center">
                  <v-btn
                    color="primary"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    class="ml-5"
                    ><v-icon>mdi-calendar</v-icon></v-btn
                  >
                  <v-text-field
                    v-model="date"
                    max-width="190px"
                    class="ml-2"
                    hint="Date"
                    persistent-hint
                    placeholder="date de l'événement"
                  ></v-text-field>
                </div>
              </template>

              <v-date-picker
                v-model="datePicker"
                no-title
                locale="fr"
                first-day-of-week="1"
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-text>
        <v-row no-gutters align="center">
          <v-textarea
            hint="Description de l'événement"
            persistent-hint
            v-model="description"
            clearable
            filled
          ></v-textarea>
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-text>
        <v-row no-gutters align="center">
          <!-- le type -->
          <v-col :cols="11" justify="center" align-self="center" align="start">
            <v-text-field
              hint="Lien vers le document"
              persistent-hint
              v-model="link"
              clearable
            ></v-text-field>
          </v-col>

          <v-col :cols="1" align-self="center" align="start">
            <div class="d-flex justify-center">
              <v-btn color="primary" icon :disabled="!link" @click="openLinkTab"
                ><v-icon>mdi-open-in-new</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn text @click="test()">Test</v-btn> -->
        <v-btn text @click="abort()" class="my-2 px-4 btn">Annuler</v-btn>
        <v-btn
          outlined
          color="primary"
          class="mr-4 my-2 px-4 btn"
          @click="confirm"
          :disabled="!canSave"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isValidFR, format_MM_DD_YYYY_to_FR } from "@/tools/date_tool.js";

export default {
  name: "AddEventDialog",
  components: {},

  mixins: [],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    /**les types d'événements de la structure juridique */
    types: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      show: this.visible,

      /**le type sélectionné */
      event: null,

      /**le type d'événement */
      type: null,

      datePicker: null,

      /**la date de l'événement */
      date: null,

      /**afficher le menu de sélection de la date */
      menu: false,

      /**la description de l'événement */
      description: null,

      /**lien vers le document */
      link: null,
    };
  },
  methods: {
    /**l'action n'est pas confirmée. on ferme le dialog.*/
    abort() {
      this.close();
    },

    /**l'action est confirmée.*/
    confirm() {
      this.$emit("confirmed", {
        type: this.type,
        date: this.date,
        description: this.description,
        link: this.link,
      });
      this.close();
    },

    /**Fermer le dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },
    test() {
      // isValidFR(this.date);
      // let dt = new Date(this.date);
      // console.log("textfield:" + this.date);
      // console.log("date:" + dt);
    },
    openLinkTab() {
      let url = this.link;
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }

      window.open(url, "_blank");
    },
  },
  computed: {
    canSave() {
      //il faut un type de contrat
      if (!this.type) return false;

      // ajouter les vérifis
      if (!isValidFR(this.date)) {
        return false;
      }

      return true;
    },
  },
  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.type = null;
        this.date = null;
        this.datePicker = null;
        this.description = null;
        this.link = null;
        this.$emit("update:visible", false);
      }
    },

    datePicker() {
      if (this.datePicker) {
        // console.error(this.datePicker);
        this.date = format_MM_DD_YYYY_to_FR(this.datePicker);
      } else {
        this.date = null;
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>

