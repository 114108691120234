<template>
  <v-container fluid>
    <v-data-table
      locale="fr-FR"
      :headers="headers"
      :items="stockholders"
      no-data-text="aucun actionnaire, membre ou adhérent"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[getItemActions()]="{ item }">
        <div class="d-flex flex-grow-0 justify-center">
          <v-icon
            small
            class="mr-2"
            color="primary"
            @click="onShowEditDialog(item)"
            v-if="canEdit"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            small
            class="mr-2"
            color="primary"
            @click="onShowDeleteDialog(item)"
            v-if="canEdit"
          >
            mdi-close-thick
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <!-- dialogue de confirmation de suppression -->
    <v-dialog v-model="showDeleteDialog" width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Confirmer la suppression</span>
        </v-card-title>

        <v-card-text
          >Voulez-vous supprimer cet actionnaire, membre ou adhérent
          ?</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDeleteDialog = false" class="btn"
            >Annuler</v-btn
          >
          <v-btn
            outlined
            color="primary"
            class="mx-2 my-2 px-4 btn"
            @click="confirmDeleteStockholder"
            >Supprimer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <EditStockholderDialog
      :visible.sync="showEditStockholderDialog"
      :currentStockholderId="selectedStockholderId"
      :currentPercent="selectedPercent"
      :currentItemId="selectedItemId"
      :used="stockholdersIds"
      @confirmed="confirmEditStockholder"
    ></EditStockholderDialog>
  </v-container>
</template>

<script>
import { roleCanEdit } from "@/service/role_service.js";

import EditStockholderDialog from "./dialogs/EditStockholderDialog.vue";

export default {
  name: "StockHolderspanel",
  components: { EditStockholderDialog },

  mixins: [],
  props: {
    stockholders: {
      type: Array,
    },
  },
  data() {
    return {
      canEdit: false,
      headers: [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "stockholder.name",
        },
        {
          text: "Pourcentage",
          align: "start",
          sortable: true,
          value: "labelPercent",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ],
      /**afficher le dialog de confirmation */
      showDeleteDialog: false,

      /**l'actionnaire sélectionné pour l'action : édition/suppression */
      selected: null,

      /**afficher le dialog d'édition' */
      showEditStockholderDialog: false,
    };
  },
  methods: {
    /**fonction pour déterminer le nom du slot du v-data-table. sinon cela provoque une erreur eslint. */
    getItemActions() {
      return `item.actions`;
    },
    onShowEditDialog(item) {
      this.selected = item;

      this.showEditStockholderDialog = true;
    },
    confirmEditStockholder(change) {
      this.showEditStockholderDialog = false;

      this.$emit("edit", change);
    },
    onShowDeleteDialog(item) {
      //on positionne l'item à supprimer
      this.selected = item;

      //on affiche le dialog de confirmation
      this.showDeleteDialog = true;
    },
    confirmDeleteStockholder() {
      //la suppression est confirmée, on ferme le dialog
      this.showDeleteDialog = false;

      //on informe de la demande de suppression
      this.$emit("delete", this.selected.id);
    },
  },
  computed: {
    selectedStockholderId() {
      return this.selected ? this.selected.stockholder.id : null;
    },
    selectedPercent() {
      return this.selected ? this.selected.percent : null;
    },
    selectedItemId() {
      return this.selected ? this.selected.id : null;
    },
    /**les identifiants des actionnaires */
    stockholdersIds() {
      let availables = this.stockholders
        .filter((e) => e.id != this.selectedStockholderId)
        .map((e) => e.stockholder.id);

      return availables;
    },
  },
  mounted() {
    this.canEdit = roleCanEdit();
  },
};
</script>

<style></style>
